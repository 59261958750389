import api from '@/ts/api';
import { Dict } from '@/ts/dict';
import { useStorage } from '@vueuse/core';
import { defineStore } from 'pinia';

export const Store = defineStore('store', {
  state: () => ({         
    profile: useStorage('stima_team_profile', {}),
    stats: useStorage('stima_team_stats', {}),
  }),
  
  getters: {

    god: (state) => state.profile.god,
    
    menu: (state) => {
      if (state.profile.id) return {}
      let list = {} 
      for (let index = 0; index < state.profile.access.length; index++) {
          const item = state.profile.access[index];
          if (!item) continue
          let p = item.split('/')
          if (p.length < 2) continue
          list[p[0]] = true
      }
      return list
    }
      
  },
  actions: {
    
    async updateStats(){
      let res = await api.send('stats',{})
      if (res.error){
       console.error("empty res")
       return res
      }
      this.stats = res.resp
      return {}
   },
    
    async login(token){
       let res = await api.send('login',{token})
       if (res.error){
        console.error("empty res")
        return res
       }
       this.profile = res.resp
       return {}
    },
    
    async logout(token){
       let res = await api.send('logout')
       if (res.error){
        console.error("empty res")
        return res
       }       
       this.profile = {}
       this.stats = {}
       const dict = Dict()
       dict.logout()
       window.location = "/"
       return {}
    },


  },
})

